/*
| ===================================================
| This File is for the home page
| ===================================================
*/
<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex xs12 pb-12>
          <v-img src="@/assets/home-green.svg" cover height="30vh">
            <v-layout wrap justify-start fill-height>
              <v-flex xs12 text-center align-self-center>
                <div class="heading">
                  Chi Siamo
                </div>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm11 md9 lg6 xl4 px-2 py-12>
          <div class="normal-text">
            <span class="bold">CB Digital</span> è la piattaforma insurtech del gruppo Consulbrokers Spa nata con l'obiettivo di digitalizzare il brokeraggio assicurativo per il mercato retail. La società opera attraverso la propria piattaforma anche nel settore cauzioni, offrendo garanzie relativamente a questo ramo esclusivamente come broker grossista/wholesale.  
            <br/>
            <br/>
            Il servizio offerto da <span class="bold">CB Digital</span> è caratterizzato dalla rapidità del digitale coniugata con l'assistenza garantita da un desk specializzato, che fornisce ai broker diretti e agli agenti una consulenza altamente professionale. I professionisti del desk analizzano e strutturano tutti i rischi sottoposti, al fine di individuare sul mercato le soluzioni migliori.
            <br/>
            <br/>
            Il portale <span class="bold">CB Digital Prodotti</span> e il portale <span class="bold">CB Digital Cauzioni</span> sono a disposizione esclusiva di intermediari assicurativi iscritti ai registri A,B o E del RUI.
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: "Home",
  components: {
    
  }
}
</script>